import { ApiError } from "@/services/BaseService";


export class Utilities {

    private static daysOfWeek = ["l", "m", "x", "j", "v", "s", "d"];

    // return the price as a string with 2 decimal places and the € symbol
    static formatPrice(value: number): string {
        return value.toFixed(2) + " €";
    }

    public static formatDateToYYYYMMDD(value: string | Date): string {
        if (value instanceof Date) {
            const year = value.getFullYear();
            const month = value.getMonth() + 1;
            const day = value.getDate();

            return `${year}-${Utilities.padZero(month)}-${Utilities.padZero(day)}`;
        } else {
            if (value) {
                return value.split("/").reverse().join("-");
            }
        }

        return "";
    }

    // returns the current date in YYYY-MM-DD format with padding zeros
    public static todayAsYYYYMMDD(): string {
        return Utilities.formatDateToYYYYMMDD(new Date());
    }

    public static formatDateToDDMMYYYY(value: string): string {
        if (value && typeof value === "string") {
            return value.split("-").reverse().join("/");
        }

        return value;
    }

    public static padZero(value: number): string {
        return value < 10 ? `0${value}` : value.toString();
    }

    // returns the day of the week for a given date in YYYY-MM-DD format, 0 = Monday, 6 = Sunday
    public static getDayOfWeekYYYYMMDD(value: string): number {
        if (value) {
            const date = value.split("-");
            if (date.length === 3) {
                const day = parseInt(date[2]);
                const month = parseInt(date[1]);
                const year = parseInt(date[0]);

                if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
                    const dayOfWeek = new Date(year, month - 1, day).getDay();
                    return dayOfWeek === 0 ? 6 : dayOfWeek - 1;
                }
            }
        }

        return -1;
    }

    public static getDayOfWeekNameYYYYMMDD(value: string): string {
        return this.daysOfWeek[Utilities.getDayOfWeekYYYYMMDD(value)] || "";
    }

    public static getBrowserLanguage(): string {
        const userLocale = navigator.language || (navigator as any).userLanguage;
        return userLocale.split("-")[0].toLowerCase();
    }

    public static formatErrorMessage(error: any): string {
        if (error instanceof ApiError) {
            return error.errorMessage;
        }
        else if (error instanceof Error) {
            return error.message || error.toString();
        } else {
            return error?.toString() || "Error desconocido";
        }
    }

    public static copyToClipboard(value: string): void {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(value).then(
                () => {
                    console.log("Link copied to clipboard");
                },
                (err) => {
                    console.error("Error copying link to clipboard", err);
                }
            );
        } else {
            const el = document.createElement("textarea");
            el.value = value;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
        }
    }


}
